import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
            }
          }
        }
      }
    }
  `)
  const listItems = data.allMarkdownRemark.edges.map(edge => {
    return (
      <li key={edge.node.frontmatter.date}>
        <h2>{edge.node.frontmatter.title}</h2>
        <p>{edge.node.frontmatter.date}</p>
      </li>
    )
  })
  return (
    <div>
      <Layout>
        <h1>Blog</h1>
        <ol>{listItems}</ol>
      </Layout>
    </div>
  )
}

export default BlogPage
